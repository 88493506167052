import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import TwoColText from '../components/TwoColText';
import ServiceSlider from '../components/ServiceSlider';
import ServiceHeader from '../singletonBlocks/ServiceHeader';

const Service = ({data: {prismicService, services}, location}) => {
  const {data} = prismicService;
  return (
    <Layout customSEO>
      <SEO title={`${data.service_name.text}`} keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']}/>
      <div className="header-spacer" />
      <ServiceHeader title={data.service_name.text} image={data.feature_image} text={data.feature_text.html} />
      <TwoColText text={data.service_description.html} classNames="bg-blue pt6-l pb6-l pb4" />
      <h2 className="blue f2 f1-l center tc mt5 mb5 mt6-l pl3 pr3">Other Solutions</h2>
      <ServiceSlider input={services.edges} />
    </Layout>
  );
};

export default Service;

Service.propTypes = {
  location: PropTypes.object.isRequired
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicService(uid: {eq: $uid}) {
      uid
      data {
        service_name {
          text
        }
        service_description {
          html
        }
        feature_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        feature_text {
          html
        }
      }
    }
    services: allPrismicService(filter: {uid: {ne: $uid}}, sort: {fields: [data___service_weight], order: DESC}) {
      edges {
        node {
          uid
          data {
            service_name {
              text
            }
            portal_text {
              text
            }
            service_weight
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
