import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ServiceHeader = ({title, image, text}) => (
  <div className="pl3 pr3 pl5-l pr0-l flex-l flex-row-reverse-l items-center relative bottom-border-on">
    <Img fluid={image.localFile.childImageSharp.fluid} className="w-100 h-auto overflow-hidden relative w-60-l" />
    <div className="w-100 w-40-l pr6-l measure-wide center">
      <h2 className="blue f2 lh-copy">{title}</h2>
      <div>
        <div dangerouslySetInnerHTML={{__html: text}} className="lh-copy pb5-l mb5 mb0-l" />
      </div>
    </div>
  </div>
);

export default ServiceHeader;

ServiceHeader.propTypes = {
  input: PropTypes.object.isRequired
};
